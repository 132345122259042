<template>
    <header class="header">
        <div class="container">
            <router-link to="/" class="header__logo-wrapper">
                <img src="@/assets/img/logo.svg" alt="" class="header__logo-img-1">
                <img src="@/assets/img/logoV2.svg" alt="" class="header__logo-img-2">
            </router-link>

            <div class="header__right">
                <div class="header__lang lang">
                    <!-- <div class="lang__main">
                        En
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33331 6.6665L7.99998 9.33317L10.6666 6.6665" stroke="#8C8C8C" stroke-width="1.2" stroke-linecap="square"/>
                        </svg>                            
                    </div> -->
                </div>
                <nav class="header__nav">
                    <ul class="header__nav-list">
                        <li class="header__nav-element">
                            <router-link to="/appsupport" class="header__nav-link">App Support</router-link>
                        </li>
                        <li class="header__nav-element">
                            <a href="#features-sec-slider" class="header__nav-link">Features</a>
                        </li>
                    </ul>
                </nav>
                <a href="https://artup.app/appstore?s=0" target="_blank" class="header__btn">Download App</a>
            </div>
        </div>
    </header>
</template>


<script>
import { fixedHeader } from '@/custom-js/fixed-header.js';

export default {
  data() {
    return {

    }
  },
  components: {
    
  },


  methods: {
    
  },

  mounted(){
    fixedHeader()
  },
}
</script>