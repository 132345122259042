<template>
    <section class="front-sec">
        <div class="front-sec__bg">
            <div id="animationContainer" class="front-sec__bg-wrapper"></div>
        </div>
       
        <div class="container">
            <img src="@/assets/img/logo-first-sec.svg" alt="" class="front-sec__logo"> 
            <h1 class="front-sec__title">
                An essential 
                <span class="front-sec__title-text-box-no-wrap">
                    t<span :class="{'activeFontAnimX1': textAnimStatus }"  class="decor-font df1"><span class="flex-font"><img src="@/assets/img/font-decor1.svg"  alt="" class="decor-font__img df1-img"></span></span>l 
                </span>
               
                for artists, 
                <span class="front-sec__title-text-box-no-wrap">
                    <span :class="{'activeFontAnimX2': textAnimStatus2 }"  class="decor-font df2"><span class="flex-font"><img src="@/assets/img/font-decor2.svg"  alt="" class="decor-font__img df2-img"></span></span>rt
                </span>
                 collectors, interior decorators, designers and 
                 <span class="front-sec__title-text-box-no-wrap">
                    <span :class="{'activeFontAnimX3': textAnimStatus3 }"  class="decor-font df3"><span class="flex-font"><img src="@/assets/img/font-decor3.svg"  alt="" class="decor-font__img df3-img"></span></span>rt
                 </span>
                  aficionados alike
            </h1>
            <div class="front-sec__btn-container">
                <a href="https://artup.app/appstore?s=0" target="_blank" class="front-sec__btn btnV1">Download App</a>
            </div>

            <a href="#seс2" class="front-sec__down-link down-link">
                <span class="down-link__text">Scroll to Explore</span>
                <span class="down-link__ar-wrapper">
                    <div class="down-link__blue-field">
                        <div class="down-link__red-field"></div>
                    </div>
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 10L11.7735 0L0.226497 0L6 10ZM5 0V1H7V0L5 0Z" fill="#FF3D40"/>
                    </svg>                        
                </span>
            </a>
        </div>
    </section>

    <section class="advantages-sec" id="seс2">
        <div class="container">
            <div class="advantages-sec__col">
                <h2 class="advantages-sec__title-sec">
                    Are you ready to art up your business?</h2>

                <a href="#features-sec" class="advantages-sec__element">
                    <h2 class="advantages-sec__title-element">
                        <div class="advantages-sec__title-top">
                            <span class="advantages-sec__ar-link">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17" cy="17" r="12" transform="rotate(-45 17 17)" fill="#C6E7EC"/>
                                    <path d="M21.9926 12.7574C21.9926 12.3432 21.6568 12.0074 21.2426 12.0074H14.4926C14.0784 12.0074 13.7426 12.3432 13.7426 12.7574C13.7426 13.1716 14.0784 13.5074 14.4926 13.5074H20.4926V19.5074C20.4926 19.9216 20.8284 20.2574 21.2426 20.2574C21.6568 20.2574 21.9926 19.9216 21.9926 19.5074V12.7574ZM13.2877 21.773L21.7729 13.2877L20.7123 12.2271L12.227 20.7123L13.2877 21.773Z" fill="white"/>
                                </svg>                              
                            </span>
                            Manage
                        </div>
                       <span class="as"><br></span>
                         your artworks</h2>
                    <p class="advantages-sec__text-element">Add photos, artwork details, dimensions, pricing and even attach documents.</p>
                </a>

                <a href="#features-sec" class="advantages-sec__element">
                    <h2 class="advantages-sec__title-element"> 
                        <div class="advantages-sec__title-top">
                            <span class="advantages-sec__ar-link">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17" cy="17" r="12" transform="rotate(-45 17 17)" fill="#EFE26E"/>
                                    <path d="M21.9926 12.7574C21.9926 12.3432 21.6568 12.0074 21.2426 12.0074H14.4926C14.0784 12.0074 13.7426 12.3432 13.7426 12.7574C13.7426 13.1716 14.0784 13.5074 14.4926 13.5074H20.4926V19.5074C20.4926 19.9216 20.8284 20.2574 21.2426 20.2574C21.6568 20.2574 21.9926 19.9216 21.9926 19.5074V12.7574ZM13.2877 21.773L21.7729 13.2877L20.7123 12.2271L12.227 20.7123L13.2877 21.773Z" fill="white"/>
                                </svg>                               
                            </span>
                            Showcase
                        </div>
                        <span class="as"><br></span>
                        your works</h2>
                    <p class="advantages-sec__text-element">Create web-based Viewing Rooms with your artwork selections to share with potential buyers.</p>
                </a>

            </div>
            
            <div class="advantages-sec__col advantages-sec__col-right">

                <a href="#features-sec" class="advantages-sec__element">
                    <h2 class="advantages-sec__title-element">
                        <div class="advantages-sec__title-top">
                            <span class="advantages-sec__ar-link">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17" cy="17" r="12" transform="rotate(-45 17 17)" fill="#FF3D40"/>
                                    <path d="M21.9926 12.7574C21.9926 12.3432 21.6568 12.0074 21.2426 12.0074H14.4926C14.0784 12.0074 13.7426 12.3432 13.7426 12.7574C13.7426 13.1716 14.0784 13.5074 14.4926 13.5074H20.4926V19.5074C20.4926 19.9216 20.8284 20.2574 21.2426 20.2574C21.6568 20.2574 21.9926 19.9216 21.9926 19.5074V12.7574ZM13.2877 21.773L21.7729 13.2877L20.7123 12.2271L12.227 20.7123L13.2877 21.773Z" fill="white"/>
                                </svg>                                     
                            </span>
                            Display art
                        </div>
                        <span class="as"><br></span>
                         in interiors</h2>
                    <p class="advantages-sec__text-element">Stage your artworks in their real dimensions in a variety of spaces created exclusively for art display.</p>
                </a>

                <a href="#features-sec" class="advantages-sec__element">
                    <h2 class="advantages-sec__title-element">
                        <div class="advantages-sec__title-top">
                            <span class="advantages-sec__ar-link">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17" cy="17" r="12" transform="rotate(-45 17 17)" fill="#0A0A0B"/>
                                    <path d="M21.9926 12.7574C21.9926 12.3432 21.6568 12.0074 21.2426 12.0074H14.4926C14.0784 12.0074 13.7426 12.3432 13.7426 12.7574C13.7426 13.1716 14.0784 13.5074 14.4926 13.5074H20.4926V19.5074C20.4926 19.9216 20.8284 20.2574 21.2426 20.2574C21.6568 20.2574 21.9926 19.9216 21.9926 19.5074V12.7574ZM13.2877 21.773L21.7729 13.2877L20.7123 12.2271L12.227 20.7123L13.2877 21.773Z" fill="white"/>
                                </svg>
                            </span>
                            Monitor
                        </div>
                        <span class="as"><br></span>
                        buyer interest</h2>
                    <p class="advantages-sec__text-element">See buyers’ reactions and comments to your Viewing Rooms in real time.</p>
                </a>

            </div>

            <img src="@/assets/img/phone-sec2.jpg" alt="" class="advantages-sec__image">

        </div>
    </section>

    <section class="features-sec" >
        <div class="container">
            <div class="features-sec__header-row">
                <div class="features-sec__line"></div>
                <h2 class="features-sec__sec-title">All in one</h2>
                <div class="features-sec__line"></div>
            </div>
            <div class="features-sec__container" id="features-sec">
                <div class="features-sec__claster">
                    <div class="features-sec__col-1">

                        <a href="#" class="features-sec__element ft-el1" @click="loanMoreInfo(4) ">
                            <div class="ft-el1__wrapper">
                                <img src="@/assets/img/a1.png" alt="" class="ft-el1__bg-image">
                                <div class="ft-el1__bg">
                                    <img src="@/assets/img/x1.svg" alt="" class="ft-el1__main-icon">
                                    <div class="ft-el1__title">
                                        <svg width="134" height="31" viewBox="0 0 134 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.504 1.60014L20.32 20.6721C21.088 22.8161 21.664 23.4881 23.104 23.7761V24.0001H14.048V23.7761C15.936 23.3921 16.288 22.4321 15.488 19.9681L14.048 15.8081H6.07998L4.57598 20.1601C3.83998 22.6241 4.47998 23.4241 6.43198 23.7761V24.0001H0.255981V23.7761C1.91998 23.4241 2.65598 22.5281 3.51998 20.1281L10.08 1.60014H13.504ZM10.08 4.22414L6.36798 15.0081H13.792L10.08 4.22414Z" fill="#0A0A0B"/>
                                            <path d="M39.5685 12.3521V21.8561C39.5685 23.1041 40.1125 23.5521 41.6485 23.7761V24.0001H33.9685V23.7761C35.3445 23.5521 35.7605 23.2001 35.7605 22.0161V12.6721C35.7605 10.2401 34.8325 9.02414 33.0085 9.02414C31.5685 9.02414 30.5765 9.72814 30.0005 11.1681V22.0161C30.0005 23.2001 30.4165 23.5521 31.7925 23.7761V24.0001H24.1125V23.7761C25.6485 23.5521 26.2245 23.1041 26.2245 21.8561V11.3921C26.2245 9.63214 25.7765 9.21614 24.1125 9.24814V9.02414L30.0005 7.42414V10.7201C30.5445 8.76814 32.4645 7.32814 34.8645 7.32814C37.9365 7.32814 39.5685 9.24814 39.5685 12.3521Z" fill="#0A0A0B"/>
                                            <path d="M59.0172 20.8641L59.3052 20.8961C58.7932 23.1681 57.3532 24.4801 55.3052 24.4801C53.5452 24.4801 52.4892 23.3921 52.3612 21.6961L52.2972 21.3121C51.8812 23.0081 50.1532 24.4801 47.7532 24.4801C45.0652 24.4801 43.4332 22.7841 43.4332 20.5761C43.4332 17.8241 45.3532 16.0641 49.2252 15.2641L52.2652 14.6561V13.8881C52.2652 10.9441 51.0172 9.47214 48.5212 9.47214C46.2172 9.47214 44.7772 10.6881 44.1692 13.1521L43.9452 13.0881C44.4252 9.50414 46.7292 7.32814 50.2812 7.32814C54.1212 7.32814 56.0412 9.66414 56.0412 13.7601V20.7681C56.0412 21.9521 56.4572 22.5601 57.2892 22.5601C58.1212 22.5601 58.6972 21.9841 59.0172 20.8641ZM49.6412 22.5921C50.9212 22.5921 51.7852 21.9841 52.2652 20.8001V15.2961L50.4412 15.6801C48.1692 16.0961 47.0492 17.3761 47.0492 19.4561C47.0492 21.2481 47.9772 22.5921 49.6412 22.5921Z" fill="#0A0A0B"/>
                                            <path d="M65.7155 0.640137V21.8561C65.7155 23.1041 66.2595 23.5521 67.7955 23.7761V24.0001H59.8275V23.7761C61.3635 23.5521 61.9395 23.1041 61.9395 21.8561V4.60814C61.9395 2.94414 61.4915 2.52814 59.8275 2.52814V2.30414L65.7155 0.640137Z" fill="#0A0A0B"/>
                                            <path d="M70.592 30.8801C69.536 30.8801 68.608 30.6561 67.776 30.1761L68.576 26.6881H68.736C69.44 27.3601 70.464 27.6801 71.84 27.6801C73.92 27.6801 75.264 27.0721 76.16 25.2801L76.384 24.7681L70.752 10.9121C70.048 9.15214 69.184 8.19214 68.192 8.03214V7.80814H76.32V8.03214C75.008 8.19214 74.464 8.60814 74.464 9.47214C74.464 9.92014 74.592 10.4641 74.816 11.1041L78.048 19.9041L81.504 11.3601C81.728 10.8161 81.856 10.3041 81.856 9.79214C81.856 8.80014 81.248 8.19214 80.032 8.03214V7.80814H84.928V8.03214C83.776 8.38414 83.264 9.08814 82.528 11.0401L76.896 25.2481C75.328 29.5681 73.28 30.8801 70.592 30.8801Z" fill="#0A0A0B"/>
                                            <path d="M95.7497 20.4801L96.0057 20.5761C95.2697 23.0081 93.6057 24.4801 91.2697 24.4801C88.9337 24.4801 87.6537 22.9761 87.6537 20.5441V8.38414H85.5737V8.19214C88.1657 7.13614 90.0857 5.34414 91.2697 2.81614H91.4297V7.84014H94.8857V8.38414H91.4297V19.9361C91.4297 21.5681 92.1017 22.5601 93.2857 22.5601C94.4697 22.5601 95.2697 21.8561 95.7497 20.4801Z" fill="#0A0A0B"/>
                                            <path d="M102.459 5.08814C101.147 6.52814 98.3947 5.50414 98.5547 3.52014C98.5547 2.20814 99.5147 1.24814 100.827 1.24814C102.107 1.24814 103.099 2.24014 103.099 3.52014C103.099 4.12814 102.875 4.67214 102.459 5.08814ZM102.907 7.52014V21.8561C102.907 23.1041 103.451 23.5521 104.987 23.7761V24.0001H97.0187V23.7761C98.5547 23.5521 99.1307 23.1041 99.1307 21.8561V11.4881C99.1307 9.69614 98.6827 9.31214 97.0187 9.31214V9.12014L102.907 7.52014Z" fill="#0A0A0B"/>
                                            <path d="M113.689 24.4801C111.545 24.4801 109.849 23.7121 108.569 22.2081C107.257 20.7041 106.617 18.7201 106.617 16.2561C106.617 13.5361 107.353 11.3921 108.793 9.76014C110.233 8.12814 112.121 7.32814 114.489 7.32814C116.441 7.32814 118.137 7.68014 119.545 8.41614L118.457 14.0481H118.233C118.073 10.6881 116.665 8.76814 114.009 8.76814C111.033 8.76814 109.369 10.8801 109.369 14.5601C109.369 16.3841 109.913 17.9521 110.969 19.2961C112.025 20.6721 113.465 21.3441 115.257 21.3441C117.561 21.3441 119.001 20.3201 119.577 18.2721L119.833 18.3361C119.481 22.0801 117.113 24.4801 113.689 24.4801Z" fill="#0A0A0B"/>
                                            <path d="M127.373 24.4801C125.229 24.4801 123.373 23.9361 121.805 22.8481L122.637 18.1121L122.893 18.1441C123.309 21.3441 125.549 23.4881 128.237 23.4881C130.189 23.4881 131.277 22.4321 131.277 20.9281C131.277 19.8081 130.509 18.9441 128.973 18.3681L125.197 16.7681C123.181 15.9041 122.157 14.4641 122.157 12.4481C122.157 9.60014 124.397 7.32814 128.365 7.32814C130.317 7.32814 131.981 7.74414 133.357 8.57614L132.461 12.8001H132.205C131.597 9.76014 130.029 8.22414 127.469 8.22414C125.549 8.22414 124.589 9.18414 124.589 10.4961C124.589 11.6161 125.293 12.4801 126.669 13.0241L130.381 14.5601C132.525 15.4561 133.613 16.8961 133.613 18.8801C133.613 22.1121 131.213 24.4801 127.373 24.4801Z" fill="#0A0A0B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="#" class="features-sec__element ft-el2" @click="loanMoreInfo(7) ">
                            <div class="ft-el2__wrapper">
                                <img src="@/assets/img/a2.svg" alt="" class="ft-el2__img">
                                <div class="ft-el2__content">
                                    <img src="@/assets/img/x2.svg" alt="" class="ft-el2__main-icon">
                                    <p class="ft-el2__title">Export</p>
                                </div>
                            </div>
                        </a>

                    </div>

                    <div class="features-sec__col-2">

                        <a href="#" class="features-sec__element ft-el3" @click="loanMoreInfo(1) ">
                            <div class="ft-el3__wrapper">
                                <p class="ft-el3__title">Showcase on the Web</p>
                                <p class="ft-el3__subtitle">in stunning Viewing Rooms</p>
                            </div>
                            <div class="ft-el3__icon-anim">
                                <div class="ft-el3__icon-anim-col">
                                    <div class="ft-el3__icon-anim-el ft-el3__icon-anim-el1"></div>
                                    <div class="ft-el3__icon-anim-el ft-el3__icon-anim-el2"></div>
                                </div>
                                <div class="ft-el3__icon-anim-col">
                                    <div class="ft-el3__icon-anim-el ft-el3__icon-anim-el3"></div>
                                    <div class="ft-el3__icon-anim-el ft-el3__icon-anim-el4"></div>
                                </div>
                            </div>
                        </a>

                        <img src="@/assets/img/palm.png" alt="" class="features-sec__palm-down">

                        <a href="#" class="features-sec__element ft-el4" @click="loanMoreInfo(3) ">
                            <img src="@/assets/img/a4.svg" alt="" class="ft-el4__bg-img">
                            <div class="ft-el4__wrapper">
                                <div class="ft-el4__content">
                                    <img src="@/assets/img/x4-dop.svg" alt="" class="ft-el4__mob-icon">
                                    <img src="@/assets/img/x4.svg" alt="" class="ft-el4__main-icon">
                                    <p class="ft-el4__title">Realistic Interiors</p>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>

                <div class="features-sec__claster">
                    <div class="features-sec__col-3">

                        <a href="#" class="features-sec__element ft-el5" @click="loanMoreInfo(0) ">
                            <div class="ft-el5__wrapper">
                                <img src="@/assets/img/a5.svg" alt="" class="ft-el5__bg">
                                <p class="ft-el5__title">Manage Your Art Portfolio</p>
                                <img src="@/assets/img/x5-mob.svg" alt="" class="ft-el5__main-icon-mob">
                                <img src="@/assets/img/x5.svg" alt="" class="ft-el5__main-icon">
                             
                            </div>
                        </a>

                        <div class="features-sec__sub-container features-sec__sub-container-doble">

                            <a href="#" class="features-sec__element ft-el6"  @click="loanMoreInfo(2) ">
                                <img src="@/assets/img/x6.svg" alt="" class="ft-el6__main-icon">
                                <div class="ft-el6__wrapper">
                                    <img src="@/assets/img/a6.png" alt="" class="ft-el6__bg">
                                    <p class="ft-el6__title">Easy Cropping</p>
                                </div>
                            </a>

                            <a href="#" class="features-sec__element ft-el7" @click="loanMoreInfo(5) ">
                                <div class="ft-el7__wprapper">
                                    <img src="@/assets/img/a7.png" alt="" class="ft-el7__bg">
                                    <p class="ft-el7__title">Background
                                        Removal</p>
                                </div>
                            </a>

                        </div>

                        <div class="features-sec__sub-container">

                            <a href="#" class="features-sec__element ft-el8" @click="loanMoreInfo(6) "> 
                                <img src="@/assets/img/x8.png" alt="" class="ft-el8__main-icon">
                                <div class="ft-el8__wrapper">
                                    <img src="@/assets/img/a8.png" alt="" class="ft-el8__icon">
                                    <img src="@/assets/img/bg8.png" alt="" class="ft-el8__bg">
                                    <p class="ft-el8__title">Staging<br> in your Own <br>Interior</p>
                                </div>
                            </a>

                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="download-sec">
        <div class="container">
            <img src="@/assets/img/ar-x.svg" alt="" class="download-sec__ar">
            <div class="download-sec__wrap">
                <img src="@/assets/img/logo.svg" alt="" class="download-sec__logo">
                <h2 class="download-sec__title">Try Now for Free</h2>
                <p class="download-sec__subtitle">Download on the App Store</p>
                <div class="download-sec__btn-container">
                    <a href="https://artup.app/appstore?s=0" target="_blank" class="download-sec__btn btnV1">Download App</a>
                </div>
            </div>
        </div>
    </section>

    <section class="features-sec-slider" >
        <div class="container">
            <h2 class="features-sec-slider__title">Features</h2>

            <div class="features-sec-slider__wrapper" id="features-sec-slider">

                <div class="features-sec-slider__element features-sec-slider__el1" id="manage">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft1.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Art Portfolio Management</h3>
                                <p class="features-sec-slider__text-wrapper"> Store and manage your art portfolio in one place. Add photos, artwork details, dimensions, pricing and even attach documents.</p>
                        </div>
                        <img src="@/assets/img/ftl1.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el2" id="showcase">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft2.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Showcase Your Art In Viewing Rooms</h3>
                                <p class="features-sec-slider__text-wrapper">Create Viewing Rooms with unique URLs to send to clients via email, messengers or social networks. Viewers can review your proposed artworks on a landing page in their browser and leave their comments and reactions (Love it, Not for me, etc.) to each piece. Your ArtUp profile information will automatically display at the bottom of the page.</p>
                        </div>
                        <img src="@/assets/img/ftl2.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el3" id="smart-cropping">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft3.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Smart Cropping</h3>
                            <p class="features-sec-slider__text-wrapper">Crop your artwork from a photo before hanging it on the wall of an interior space or using it as a separate image.</p>
                        </div>
                        <img src="@/assets/img/ftl3.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el4" id="realistick">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft4.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Display Art in Exclusive Realistic Interiors</h3>
                                <p class="features-sec-slider__text-wrapper">Exhibit your artworks in their real dimensions in stylish interiors created exclusively for ArtUp users by interior design experts. Artworks are placed anywhere on the wall behind furniture and décor objects to create a photorealistic rendering. Change wall colours and adjust drop shadows as needed.</p>
                        </div>
                        <img src="@/assets/img/ftl4.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el5" id="analytics">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft5.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Viewing Room Analytics</h3>
                                <p class="features-sec-slider__text-wrapper">Review how potential buyers interact with your Viewing Room by seeing their opens, views, and feedback instantly in the app. ArtUp automatically determines a client’s interest in your artworks based on a number of factors.</p>
                        </div>
                        <img src="@/assets/img/ftl5.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el6" id="background-removal">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft6.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Background Removal</h3>
                            <p class="features-sec-slider__text-wrapper">Remove background from photos of sculptures with our AI-powered technology.</p>
                        </div>
                       
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el7" id="interior">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft7.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Hang art in your own interior</h3>
                            <p class="features-sec-slider__text-wrapper">Upload your own photo of an interior space and display your artwork in it. Change wall colours, adjust perspective and drop shadows for a realistic look.</p>
                        </div>
                        <img src="@/assets/img/ftl7.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

                <div class="features-sec-slider__element features-sec-slider__el8" id="high-resolution">
                    <div class="features-sec-slider__wrapper-content">
                        <img src="@/assets/img/ft8.png" alt="" class="features-sec-slider__img">
                        <div class="features-sec-slider__text">
                            <h3 class="features-sec-slider__title-incide">Export
                                in high resolution</h3>
                            <p class="features-sec-slider__text-wrapper">Export your artwork photos in all the popular social media formats in 4K resolution.</p>
                        </div>
                        <img src="@/assets/img/ftl8.svg" alt="" class="features-sec-slider__icon">
                    </div>
                </div>

           </div>
        </div>
    </section>

    <section class="download-sec-down">
        <div class="container">
            <div class="download-sec__wrap">
                <img src="@/assets/img/logo.svg" alt="" class="download-sec__logo">
                <h2 class="download-sec__title">ArtUp.</h2>
                <p class="download-sec__subtitle">Portfolio & Staging</p>
                <div class="download-sec__btn-container">
                    <a href="https://artup.app/appstore?s=0" target="_blank" class="download-sec__btn btnV1 btnV1-down">
                        <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8741 7.01956C15.2416 7.4135 14.7177 7.96425 14.3514 8.62027C13.9851 9.27629 13.7884 10.0161 13.7797 10.7705C13.7822 11.6196 14.0295 12.4493 14.4909 13.1568C14.9524 13.8644 15.6077 14.4186 16.3757 14.751C16.0729 15.7449 15.6248 16.6868 15.0463 17.545C14.2187 18.7571 13.3533 19.969 12.0365 19.969C10.7197 19.969 10.3811 19.1908 8.86356 19.1908C7.38371 19.1908 6.85695 19.9946 5.65299 19.9946C4.44902 19.9946 3.60886 18.8718 2.64314 17.494C1.36749 15.5638 0.666414 13.3001 0.624023 10.9746C0.624023 7.14717 3.06959 5.11869 5.4774 5.11869C6.75665 5.11869 7.82267 5.97339 8.62532 5.97339C9.39032 5.97339 10.5818 5.06755 12.0365 5.06755C12.7844 5.04792 13.5257 5.21569 14.1949 5.55609C14.8641 5.89648 15.4408 6.39906 15.8741 7.01956ZM11.3468 3.44734C11.988 2.67993 12.351 1.71191 12.3752 0.704984C12.3762 0.572242 12.3636 0.439752 12.3375 0.30969C11.236 0.419154 10.2174 0.953247 9.49062 1.80242C8.84317 2.53925 8.46649 3.48142 8.42459 4.4688C8.42508 4.58888 8.43769 4.70859 8.46224 4.82605C8.54908 4.84275 8.63724 4.85131 8.72562 4.85162C9.23328 4.81053 9.72742 4.66485 10.178 4.42344C10.6286 4.18203 11.0263 3.84994 11.3468 3.44734Z" fill="white"/>
                        </svg>    
                        Download on the App Store
                    </a>
                </div>

                <!-- <div class="download-sec-down__anim-wrapper">
                    <div id="download-sec-down-qr"></div>
                </div> -->
                <div class="qr-wrapper">
                    <img src="@/assets/img/qr-anim.png" alt="" class="qr-wrapper__anim-image">
                    <img  src="@/assets/img/qr.png" class="qr-wrapper__statick-image" alt="">
                </div>
                
            </div>
        </div>
    </section>

    <popupFearures :dataPopupStatus="popupStatus" :indxSlide="currentIndex" @popupStatus="closePopup" />

</template>

<script>
import lottie from 'lottie-web'; 
import 'intersection-observer';
import { features } from '@/custom-js/features.js';
import { animScroll } from '@/custom-js/anim-scroll.js';
import { featuresHeaderFixed } from '@/custom-js/fixed-features-header.js';
import popupFearures from '@/components/popup-slider.vue'

export default {
  data() {
    return {
        isAnimationVisible: false,
        isAnimationVisibleQr: false,
        textAnimStatus: false,
        textAnimStatus2: false,
        textAnimStatus3: false,
        currentIndex: null,
        popupStatus: false,
    }
  },
  components: {
    popupFearures,
  },


  methods: {
    openPopup(){
        this.popupStatus = true
    },
    closePopup(data){
        this.popupStatus = data
    },

    loanMoreInfo(inx){
        this.currentIndex = +inx + 1
        this.popupStatus = true
    },



    loadAnimation() {
      const animationContainer = document.getElementById('animationContainer');
      const animationData = require('@/assets/json-anim/front-sec-bg.json');

      lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: this.isAnimationVisible, 
        animationData: animationData,
      });

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0, 
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          this.isAnimationVisible = entry.isIntersecting;
          if (this.isAnimationVisible) {
            lottie.play(); 
          } else {
            lottie.pause(); 
          }
        });
      }, options);

      observer.observe(animationContainer);
    },

    // loadAnimationV2() {
    //     const animationContainer = document.getElementById('animationContainer');
    //     const animationData = require('@/assets/json-anim/front-sec-bg.json'); 
    //     lottie.loadAnimation({
    //         container: animationContainer,
    //         renderer: 'svg', 
    //         loop: true,
    //         autoplay: this.isAnimationVisible,
    //         animationData: animationData,
    //     });
        
    //     document.addEventListener('scroll', function(){

    //         const options = {
    //             root: null,
    //             rootMargin: '0px',
    //             threshold: 0,
    //         };

    //         const observer = new IntersectionObserver((entries) => {
    //             entries.forEach((entry) => {
    //             this.isAnimationVisible = entry.isIntersecting;
    //             if (this.isAnimationVisible) {
    //                 lottie.play(); 
    //             } else {
    //                 lottie.pause(); 
    //             }
    //             });
    //         }, options);

    //         observer.observe(animationContainer);

    //     })
    // },

    // loadAnimationQr() {
    //   const animationContainer = document.getElementById('download-sec-down-qr');
    //   const animationData = require('@/assets/json-anim/qr.json');

    //   lottie.loadAnimation({
    //     container: animationContainer,
    //     renderer: 'svg',
    //     loop: true,
    //     autoplay: this.isAnimationVisibleQr,
    //     animationData: animationData,
    //   });

    //   const options = {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.1,
    //   };

    //   const observer = new IntersectionObserver((entries) => {
    //     entries.forEach((entry) => {
    //       this.isAnimationVisibleQr = entry.isIntersecting;
    //       if (this.isAnimationVisibleQr) {
    //         lottie.play();
    //       } else {
    //         lottie.pause();
    //       }
    //     });
    //   }, options);

    //   observer.observe(animationContainer);
    // },

  },

  mounted(){
    // setTimeout(() => {
    //     this.loadAnimationV2()
    // }, 300);

     setTimeout(() => {
        this.loadAnimation()
    }, 300);



    // this.loadAnimationQr();

    features();
    animScroll();
    featuresHeaderFixed();

    //text animation in first screen 
    setTimeout(() => {
      this.textAnimStatus = true; 
    }, 1000);

    setTimeout(() => {
      this.textAnimStatus2 = true; 
    }, 1200);

    setTimeout(() => {
      this.textAnimStatus3 = true; 
    }, 1400);


  },

  
}
</script>