export function featuresHeaderFixed() {
    let headerFeatures = document.querySelector('.features-sec-slider__title')

    let containerFeatures = document.querySelector('.features-sec-slider__wrapper')
    let containerFeaturesHeight = containerFeatures.offsetHeight
    let containerFeaturesOfsetTop = containerFeatures.offsetTop

    let arrayFeatures = document.querySelectorAll('.features-sec-slider .features-sec-slider__element')
    let numberArrayElements = arrayFeatures.length
    let heightLastFeatures = arrayFeatures[numberArrayElements - 1].offsetHeight;

    let currentOffset = +containerFeaturesHeight + +containerFeaturesOfsetTop - +heightLastFeatures;

    window.addEventListener('scroll', function () {
        let scrollY = window.scrollY;

        if (scrollY >= currentOffset) {
            headerFeatures.classList.add('fixed-features-title')
        }
        else {
            headerFeatures.classList.remove('fixed-features-title')
        }
    })
}