<template>
    <footer class="footer" :class="{ 'special-footer-class': isSpecialFooter }">
        <div class="container">
            <div class="footer__left">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.2941" cy="11.2941" r="11.2941" fill="#FF3D40"/>
                    <path d="M17.1462 11.7933C17.4218 11.5176 17.4218 11.0707 17.1462 10.795L12.6539 6.30279C12.3783 6.02712 11.9313 6.02712 11.6557 6.30279C11.38 6.57845 11.38 7.02539 11.6557 7.30105L15.6488 11.2941L11.6557 15.2872C11.38 15.5629 11.38 16.0098 11.6557 16.2855C11.9313 16.5611 12.3783 16.5611 12.6539 16.2855L17.1462 11.7933ZM5.35291 12L16.647 12V10.5882L5.35291 10.5882V12Z" fill="white"/>
                    </svg>
                    
                <p class="footer__copy">
                    © 2022-2024 Art eService Ltd <br>Registered in England. <br>Company number 12442182. <br>All rights reserved.
                </p>
            </div>
            <nav class="footer__right">
                <ul class="footer__nav-list">
                    <li class="footer__list-element">
                        <a href="https://www.facebook.com/maartup" target="_blank" class="footer__list-link">Facebook</a>
                    </li>
                    <li class="footer__list-element">
                        <a href="https://www.instagram.com/artup.app" target="_blank" class="footer__list-link">Instagram</a>
                    </li>
                    <li class="footer__list-element">
                        <a href="https://twitter.com/artup_app" target="_blank" class="footer__list-link">Twitter</a>
                    </li>
                    <li class="footer__list-element">
                        <a href="https://www.linkedin.com/company/artupapp" target="_blank" class="footer__list-link">LinkedIn</a>
                    </li>
                    <li class="footer__list-element">
                        <a href="https://www.youtube.com/channel/UCnf0accTHGAD2wwptsYIp4A" target="_blank" class="footer__list-link">YouTube</a>
                    </li>
                    <li class="footer__list-element">
                        <a href="https://www.tiktok.com/@artupapp" target="_blank" class="footer__list-link">TikTok</a>
                    </li>
                    <!-- <li class="footer__list-element">
                        <a href="#" class="footer__list-link">Behance</a>
                    </li>
                    <li class="footer__list-element">
                        <a href="#" class="footer__list-link">VSCO</a>
                    </li> -->
                </ul>
                <ul class="footer__nav-list">
                    <li class="footer__list-element">
                        <router-link to="/appsupport" class="footer__list-link">App Support</router-link>
                    </li>
                    <li class="footer__list-element">
                        <router-link to="/terms-conditions" class="footer__list-link">Terms & Conditions</router-link>
                    </li>
                    <li class="footer__list-element">
                        <router-link to="/privacy-policy" class="footer__list-link">Privacy Policy</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </footer>
</template>


<script>
export default {
  props: {
    isSpecialFooter: {
      type: Boolean,
      default: false
    }
  }
};
</script>


