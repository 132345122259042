<template>
    <div class="popup-features" :class="{activePopup : dataPopupStatus}">

        <div class="popup-features__bg"></div>

        <div class="popup-features__slider-wrapper">
            <div class="popup-features__slider-wrapper-main">
                <div class="popup-features__close" @click="closePopupEvent">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8L16 16" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 8L8 16" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>              
                </div>
                <div class="popup-features__slider-wrapper-container">
                    
                    <swiper ref="mySwiper"
                    :modules="modules"
                    :slides-per-view="1"
                    :space-between="0"
                    :navigation="true"
                    :pagination="{ clickable: true }"
                    :autoHeight="true"
                    speed="1000"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    >
                    
                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el1" id="manage">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft1.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Art Portfolio Management</h3>
                                        <p class="features-sec-slider__text-wrapper"> Store and manage your art portfolio in one place. Add photos, artwork details, dimensions, pricing and even attach documents.</p>
                                </div>
                                <img src="@/assets/img/ftl1.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el2" id="showcase">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft2.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Showcase Your Art In Viewing Rooms</h3>
                                        <p class="features-sec-slider__text-wrapper">Create Viewing Rooms with unique URLs to send to clients via email, messengers or social networks. Viewers can review your proposed artworks on a landing page in their browser and leave their comments and reactions (Love it, Not for me, etc.) to each piece. Your ArtUp profile information will automatically display at the bottom of the page.</p>
                                </div>
                                <img src="@/assets/img/ftl2.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el3" id="smart-cropping">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft3.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Smart Cropping</h3>
                                    <p class="features-sec-slider__text-wrapper">Crop your artwork from a photo before hanging it on the wall of an interior space or using it as a separate image.</p>
                                </div>
                                <img src="@/assets/img/ftl3.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el4" id="realistick">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft4.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Display Art in Exclusive Realistic Interiors</h3>
                                        <p class="features-sec-slider__text-wrapper">Exhibit your artworks in their real dimensions in stylish interiors created exclusively for ArtUp users by interior design experts. Artworks are placed anywhere on the wall behind furniture and décor objects to create a photorealistic rendering. Change wall colours and adjust drop shadows as needed.</p>
                                </div>
                                <img src="@/assets/img/ftl4.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el5" id="analytics">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft5.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Viewing Room Analytics</h3>
                                        <p class="features-sec-slider__text-wrapper">Review how potential buyers interact with your Viewing Room by seeing their opens, views, and feedback instantly in the app. ArtUp automatically determines a client’s interest in your artworks based on a number of factors.</p>
                                </div>
                                <img src="@/assets/img/ftl5.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el6" id="background-removal">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft6.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Background Removal</h3>
                                    <p class="features-sec-slider__text-wrapper">Remove background from photos of sculptures with our AI-powered technology.</p>
                                </div>
                            
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el7" id="interior">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft7.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Hang art in your own interior</h3>
                                    <p class="features-sec-slider__text-wrapper">Upload your own photo of an interior space and display your artwork in it. Change wall colours, adjust perspective and drop shadows for a realistic look.</p>
                                </div>
                                <img src="@/assets/img/ftl7.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="features-sec-slider__element features-sec-slider__el8" id="high-resolution">
                            <div class="features-sec-slider__wrapper-content">
                                <img src="@/assets/img/ft8.png" alt="" class="features-sec-slider__img">
                                <div class="features-sec-slider__text">
                                    <h3 class="features-sec-slider__title-incide">Export
                                        in high resolution</h3>
                                    <p class="features-sec-slider__text-wrapper">Export your artwork photos in all the popular social media formats in 4K resolution.</p>
                                </div>
                                <img src="@/assets/img/ftl8.svg" alt="" class="features-sec-slider__icon">
                            </div>
                        </div>
                    </swiper-slide>

                    </swiper>

                </div>
            </div>
        </div>
       
    </div> 
</template>



<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
  data() {
    return {
    }
  },
  props:{
    dataPopupStatus: Boolean,
    indxSlide: Number
  },
  components: {
    Swiper,
    SwiperSlide,
  },

  methods: {
    closePopupEvent(){
        this.$emit('popupStatus', false);
    }
  },
  watch: {
    indxSlide(newVal, oldVal) {
        this.slideTo(+this.indxSlide)
    }
  },
  setup() {
    let swiperData = ''

    let onSwiper = (swiper) => {
    console.log(swiper);
    swiperData = swiper;
    };

    let onSlideChange = (data) => {
    console.log(data.activeIndex);
    let indexColor = data.activeIndex
    let collordata = "red";

    if(indexColor == 0){
        collordata = '#F4FAFB'
    }
        else if(indexColor == 1){
        collordata = '#fff'
    }
    else if(indexColor == 2){
        collordata = '#F4FAFB'
    }
    else if(indexColor == 3){
        collordata = '#F4FAFB'
    }
    else if(indexColor == 4){
        collordata = '#FAF6F8'
    }
    else if(indexColor == 5){
        collordata = '#FAF6F8'
    }
    else if(indexColor == 6){
        collordata = '#FAF6F8'
    }
    else if(indexColor == 7){
        collordata = '#F4FAFB'
    }
    document.querySelector('.popup-features__bg').style.background = collordata;     
    };

    const slideTo = (index) => {
    let indexColor = index
    let collordata;
    if(indexColor == 0){
        collordata = '#F4FAFB'
    }
        else if(index == 1){
        collordata = '#fff'
    }
    else if(index == 2){
        collordata = '#F4FAFB'
    }
    else if(index == 3){
        collordata = '#F4FAFB'
    }
    else if(index == 4){
        collordata = '#FAF6F8'
    }
    else if(index == 5){
        collordata = '#FAF6F8'
    }
    else if(index == 6){
        collordata = '#FAF6F8'
    }
    else if(index == 7){
        collordata = '#F4FAFB'
    }
    document.querySelector('.popup-features__bg').style.background = collordata;;
    swiperData.slideTo(index - 1, 0); 
    };

    return {
    onSwiper,
    onSlideChange,
    slideTo,

    modules: [Navigation, Pagination, A11y],
    };
  },
}
</script>